<template>
  <div class="events-page players-page w-100">
    <PlayerListNew class="pb-2 pb-xxl-5" />
    <SectionPhone class="mt-5 pb-3 pt-3 pt-md-5 pb-md-5 pb-xxl-5 bg-white" />
  </div>
</template>

<script>
import PlayerListNew from "./PlayerListNew";
import SectionPhone from "@/views/Landing/SectionPhone";

export default {
  name: "Players",
  title: "players",
  components: {
    PlayerListNew,
    SectionPhone
  },
};
</script>