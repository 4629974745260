<template>
  <div class="section-content section-pickup pt-4 w-100">
    <b-container class="container-xlg pl-xxl-0 pr-xxl-0">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="Player"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="選手情報"
        />
        <div
          class="
            filter-search filter-row
            d-flex
            flex-column flex-md-row
            justify-content-center
          "
        >
          <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            :menu-class="[
              'w-100',
              isAnimatedMenu
                ? 'animate__animated animate__fadeIn'
                : 'animate__animated animate__fadeOut',
            ]"
            @show="showDropdownMenu"
            @hide="hideDropdownMenu"
            class="dropdown-search mb-1 mb-md-3 mx-md-2"
          >
            <template #button-content>
              <span
                class="text-truncate selected-name"
                :title="selected_name"
                >{{ selected_name || "ゲームタイトル" }}</span
              >
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group class="mb-0" label-size="sm">
                <b-form-input
                  v-model="search_name_game"
                  id="tag-search-input"
                  type="search"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <li class="dropdown-events">
              <ul class="list-group list-unstyled">
                <b-dropdown-item-button
                  v-for="(option, index) in availableOptions"
                  :key="index"
                  @click="onOptionClick({ option })"
                  class="text-truncate"
                >
                  <span
                    :title="option.text"
                    class="text-truncate d-block selected-name"
                    >{{ option.text }}</span
                  >
                </b-dropdown-item-button>
              </ul>
            </li>
            <b-dropdown-text v-if="availableOptions.length === 0">
              該当ゲームタイトルがありません。
            </b-dropdown-text>
          </b-dropdown>
          <b-form-input
            class="name-event mb-2 mb-md-3 mx-md-2"
            v-model="freeword"
            placeholder="フリーワード"
          ></b-form-input>
          <b-button
            @click="handleSearch"
            :disabled="isSubmit"
            class="primary mb-4 mb-md-3 mx-md-2"
            block
            >検索</b-button
          >
        </div>
      </div>
      <div
        class="
          limit-event
          filter-search
          d-flex
          justify-content-between
          mt-0
          mb-1
          mt-md-3
          mb-md-0
        "
        v-if="isResult"
      >
        <span class="txt-lable">{{ rows }}件中{{ rowsVisible }}件表示</span>
        <b-form-select
          v-model="perPage"
          :options="options_limit"
          class="mb-3 text-truncate select-limit"
        ></b-form-select>
      </div>
      <esports-pickup-loading
        v-if="isLoading"
        :m_col="1"
        :md_cols="2"
        :events="6"
      />
      <b-row
        class="mt-md-3 row-player"
        v-else
        cols="1"
        cols-md="2"
        align-v="stretch"
      >
        <b-col
          class="col-player mb-3 mb-md-3"
          v-for="(player, index) in playersInfo"
          :key="index"
        >
          <b-card
            class="
              h-100
              pl-3
              py-3
              border-0
              position-relative
              card-stagger card-player
            "
            no-body
          >
            <div class="d-flex pl-md-1">
              <div
                class="img-thumb"
                role="button"
              >
                <span @click="showModal(player.playerId)">
                  <b-avatar
                    size="90px"
                    :src="player.picture"
                    alt="Image"
                    class="img-event"
                    v-bind="mainProps('#fff')"
                    show
                  ></b-avatar>
                </span>
                <esports-loading-button
                  v-if="player.isLoading"
                  width="50px"
                  height="50px"
                />
              </div>
              <div class="article-content">
                <!-- <div class="d-flex">
                  <span v-if="!player.teams.length" class="top-title box-black"
                    >無所属</span
                  >
                  <template v-else>
                    <span class="top-title box-black">{{
                      getStringTeams(player.teams)
                    }}</span>
                  </template>
                </div> -->
                <h4
                  class="mb-0 cursor-pointer position-relative d-table"
                  :class="{ 'mt-md-4': player.gameTitles && !player.gameTitles.length }" 
                  role="button"
                  @click="showModal(player.playerId)"
                >
                  <span>{{ player.name }}</span>
                  <esports-loading-button
                    v-if="player.isLoading"
                    width="20px"
                    height="20px"
                  />
                </h4>
                <b-card-header @click="showModal(player.playerId)" v-if="player.gameTitles && player.gameTitles.length" class="pl-0 mt-1 pb-0 d-lg-flex">
                  <!-- <div class="title-label pr-2">ゲームカテゴリ</div> -->
                  <div class="title-game my-md-1">
                    <template v-for="(team, index) in player.gameTitles">
                      <div class="d-flex" :key="index" v-if="index < 2">
                        <esports-icon-game
                          width="21"
                          height="20"
                          v-if="index === 0"
                        />
                        <esports-icon-game
                          width="21"
                          height="20"
                          v-else
                          :style="{ visibility: 'hidden' }"
                        />
                        <span class="e-football"
                          >{{ team }}
                          {{
                            index === 1 && player.gameTitles.length > 2
                              ? "..."
                              : ""
                          }}</span
                        >
                      </div>
                    </template>
                  </div>
                </b-card-header>
                <div class="title-twitter" v-if="player.twitter">
                  <esports-twitter color="#A2A3A6" height="9px" width="9px" />
                  <div class="link-twitter" v-if="player.twitter"><b-link :href="player.twitter" target="_blank">{{ player.name }}</b-link></div>
                  <div class="link-twitter" v-else><span>{{ player.name }}</span></div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <esports-not-result v-if="hideBeforeLoading && !isResult">
        <span role="button" @click="resetFilter" class="ext-reset"
          >リセット</span
        >
      </esports-not-result>
      <div
        class="
          limit-event
          filter-search
          d-flex
          justify-content-between
          mt-0
          mb-1
          mt-md-0
          mb-md-0
        "
        v-if="isResult"
      >
        <span class="txt-lable">{{ rows }}件中{{ rowsVisible }}件表示</span>
        <b-form-select
          v-model="perPage"
          :options="options_limit"
          class="mb-3 text-truncate select-limit"
        ></b-form-select>
      </div>
      <b-pagination
        v-if="isPaging"
        v-model="page"
        :total-rows="rows"
        :per-page="perPage"
        size="md"
        align="center"
        class="page-nav my-3"
      >
        <template #first-text="{ disabled }">
          <esports-icon-double-arrow-left v-if="disabled" />
          <esports-icon-double-arrow-left v-else color="#231F20" />
        </template>
        <template #prev-text="{ disabled }">
          <esports-icon-arrow-left v-if="disabled" />
          <esports-icon-arrow-left v-else color="#231F20" />
        </template>

        <template #last-text="{ disabled }">
          <esports-icon-double-arrow-right v-if="disabled" />
          <esports-icon-double-arrow-right v-else color="#231F20" />
        </template>
        <template #next-text="{ disabled }">
          <esports-icon-arrow-right v-if="disabled" />
          <esports-icon-arrow-right v-else color="#231F20" />
        </template>
      </b-pagination>
      <esports-loading-button
        class="overlay-content-page"
        width="100"
        height="100"
        v-if="isLoading"
      />
    </b-container>
    <PlayerDetailModal
      :currentPlayer="currentPlayer"
      :isShowPlayerModal="isShowPlayerModal"
      @resetModal="resetGamerModal"
      @showLoginModal="showLoginModal"
    />

    <LoginModal :isShowLoginModal="isShowLoginModal" @resetModal="resetModal" />
  </div>
</template>

<script>
import moment from "moment";
import LoginModal from "@/components/Modals/LoginModal";
import PlayerDetailModal from "@/components/Modals/PlayerDetailModal";

export default {
  name: "PlayerListNew",
  components: { PlayerDetailModal, LoginModal },
  data() {
    return {
      playersInfo: [],
      limit: false,
      isLoading: false,
      options_limit: [
        {
          value: 10,
          text: "10件",
        },
        {
          value: 20,
          text: "20件",
        },
        {
          value: 40,
          text: "40件",
        },
        {
          value: 60,
          text: "60件",
        },
        {
          value: 70,
          text: "70件",
        },
        {
          value: 100,
          text: "100件",
        },
      ],
      options_name: [],
      selected_type: null,
      selected_name: null,
      selected_status: null,
      selected_sort: null,
      freeword: "",
      date: "",
      page: 1,
      rows: 1,
      perPage: 40,
      search_name_game: "",
      currentPlayer: null,
      isShowPlayerModal: false,
      isShowLoginModal: false,
      currentPage: 1,
      isReset: false,
      isSubmit: false
    };
  },
  watch: {
    perPage(nVal) {
      this.page = 1;
      this.currentPage = 1;
      if (this.isReset) return;

      this.handleFilterPlayers(this.page);
    },
    page(nVal) {
      if (this.page === this.currentPage) {
        this.currentPage = 1;
        return;
      }
      this.currentPage = nVal;

      this.handleFilterPlayers(this.page);
    },
  },
  computed: {
    criteria() {
      return this.search_name_game.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options_name;

      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.text.toLowerCase().indexOf(criteria) > -1
        );
      }

      // Show all options available
      return options;
    },
    isPaging() {
      return this.rows > this.perPage;
    },
    isResult() {
      return this.playersInfo.length;
    },
    rowsVisible() {
      if (this.page === 1) {
        return this.playersInfo.length;
      }
      return this.perPage * (this.page - 1) + this.playersInfo.length;
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.getGameList(),
      this.getPlayerList({
        perPage: this.perPage,
        page: this.page,
      }),
    ]);
    this.hideBeforeLoading = true;
  },
  methods: {
    async getGameList() {
      const result = await this.$store
        .dispatch("event/getGameList")
        .catch((err) => {
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.options_name = result.games.map((game) => {
          return {
            value: game.gameId,
            text: game.title,
          };
        });
      }
    },
    async getPlayerList(params) {
      this.isSubmit = true;
      this.isLoading = true;
      
      const result = await this.$store
        .dispatch("player/getPlayerList", params)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        
        this.rows = result.total;
        this.playersInfo = result.data.map((player, index) => {
          player.picture = `${process.env.VUE_APP_BACKEND + player.picture}`;
          player.isLoading = false;

          if (player.startDate) {
            player.startDate = moment(player.startDate).format("YYYY-MM-DD");
          }
          return player;
        });

      
        this.isReset = false;

        this.$nextTick(() => {
          this.initStagger();
          this.scrollToTop();
          this.isSubmit = false;
        });
        this.isLoading = false;
      }
    },
    async getPlayerDetail(playerId) {
      const result = await this.$store
        .dispatch("player/getPlayerDetail", playerId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.currentPlayer = result;
        this.currentPlayer.picture =
          process.env.VUE_APP_BACKEND + this.currentPlayer.picture;

        this.currentPlayer.labelGamerName = "チーム・所属・スポンサー";
        this.currentPlayer.gamerType = 0;

        this.currentPlayer.extensionInfos = this.currentPlayer.extensionInfos
          ? JSON.parse(this.currentPlayer.extensionInfos)
          : null;

        this.currentPlayer.deliverySite = this.currentPlayer.deliverySite
          ? JSON.parse(this.currentPlayer.deliverySite)
          : null;
      }
    },
    async resetFilter() {
      this.resetData();
      if (this.page === this.currentPage && !this.isReset) {
        return;
      }

      await this.handleFilterPlayers(1);
    },
    async handleSearch() {
      this.page = 1;
      this.currentPage = 1;
      await this.handleFilterPlayers(this.page);
    },
    async handleFilterPlayers(page) {
      let params = {
        perPage: this.perPage,
        page: page,
        freeword: this.freeword,
        gameId: this.gameId,
      };

      if (!params.freeword) delete params.freeword;
      if (!params.gameId) delete params.gameId;

      await this.getPlayerList(params);
    },
    async showModal(id) {
      this.setLoadingPlayer(id, true);
      await this.getPlayerDetail(id);
      this.setLoadingPlayer(id, false);
      this.isShowPlayerModal = true;
    },
    getStringTeams(teams) {
      if (teams.length === 1) return teams[0].title;
      return teams.reduce((result, elem, index) => {
        return (
          result + "" + elem.title + (teams.length - 1 !== index ? "・" : "")
        );
      }, "");
    },
    resetData() {
      this.page = 1;
      this.currentPage = 1;
      this.freeword = "";
      this.gameId = "";
      this.perPage = 40;
      this.selected_name = null;
      this.isReset = true;
    },
    onOptionClick({ option }) {
      this.search_name_game = "";
      this.selected_name = option.text;
      this.gameId = option.value;
    },
    setLoadingPlayer(id, isLoading) {
      let indexPlayer = this.playersInfo.findIndex(
        (player) => player.playerId === id
      );
      if (indexPlayer > -1) {
        this.playersInfo[indexPlayer].isLoading = isLoading;
      }
    },
    showLoginModal() {
      this.isShowLoginModal = true;
    },
    resetModal(isHideModal) {
      this.isShowLoginModal = isHideModal;
    },
    resetGamerModal(isPlayer) {
      this.isShowPlayerModal = isPlayer.isHide;
      this.currentPlayer = null;
    },
  },
};
</script>